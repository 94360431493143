@import 'node_modules/@fortawesome/fontawesome-svg-core/styles';
@import "node_modules/bootstrap/scss/bootstrap";

$mobile: 550px;
$desktop: 551px;
$base-color: #008656; // For contrast against white
$base-color-light: lighten($base-color, 50); // For contrast against black
$secondary-color: #fdf4bb;
$landing-color: #167475;
// $landing-color: #dc8998

// #ffe074
html, body {
    min-height: 100vh;
    margin: 0;
    overflow-x: hidden; }

body {
    margin: 0;
    color: #24292e;

    @media (max-width: $mobile) {
        font-size: 14px; }

    a, a:hover {
        color: #008656; }

    a.text-link {
        font-weight: 400; } }

.btn-dawn {
    background: #fdf4bb;
    color: #3a3a3a; }

.btn-dawn:hover {
    color: white;
    background: #008656; }

.btn-outline-dawn {
    color: #008656;
    border: 1px solid #008656; }

.btn-outline-dawn:hover {
    background: #fdf4bb;
    color: #3a3a3a;
    border: 1px solid #fdf4bb; }

// Less chonky tables
table.table {
    td, th {
        padding: 0.4rem; } }

span.Logo {
    display: inline-block;
    margin-right: 0.3rem;
    position: relative;
    top: -2px;

    > svg {
        width: 32px;
        height: 32px; } }

.SiteFooter {
    margin-top: 3rem;
    background-image: url("./img/lesson-footer.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 0;
    padding-top: 35%;

    @media (max-width: $mobile) {
        padding-top: 40%; }

    .container {
        position: relative; }

    ul {
        position: absolute;
        width: 100%;
        bottom: 0;
        margin: 0;
        padding: 0;
        text-align: right;

        @media (max-width: $mobile) {
            right: 0; }

        li {
            display: inline-block;
            background-color: rgba(85,85,85,0.9);
            color: white;

            a {
                display: block;
                color: #fff;
                padding: 10px 15px;
                text-decoration: none; } }

        // li:last-child
        //     padding: 10px 15px
        //     color: #3a3a3a
        //     background: transparentize(#fdf4bb, 0)

        //     a
        //         color: #3a3a3a
 } }        //         padding: 0

.LessonPage {
    font-family: 'SF Pro Display',-apple-system,BlinkMacSystemFont,'Inter','Segoe UI',Roboto,Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    color: #1a1a1a;
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 1em 0;

    padding-top: 2rem;

    .container {
        max-width: 700px; }

    section {
        margin-top: 2rem; }

    h1 {
        color: #1a1a1a;
        font-family: 'Roboto Slab',sans-serif;
        font-size: 2em;
        font-weight: 700;
        line-height: 130%;
        margin: 0.378em 0 0 0; }

    .draft-marker {
        color: rgb(200, 0, 0);
        opacity: 0.5;
        font-size: 1.5rem; }

    h3 {
        color: #757575;
        font-family: 'SF Compact Display',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
        font-size: 19px;
        font-weight: normal;
        line-height: 1.16em;
        margin-top: 4px;
        margin-bottom: 1rem; }

    table td {
        max-width: 300px; }

    blockquote {
        font-style: italic;
        padding-left: 15px;
        border-left: 4px solid $base-color; }

    .authorship {
        font-style: italic;
        margin-bottom: 1rem;
        color: #666; }

    img {
        display: block;
        margin: auto;
        max-width: 100%;
        margin-bottom: 1rem; }

    figure {
        img {
            padding: 1rem;
            border: 1px solid #ccc;
            margin-bottom: 0.5rem; }

        figcaption {
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 0.9rem;
            line-height: 1.5;
            color: #666; } } }

#references, #furtherReading {
    ol, ul {
        padding-left: 0; }

    li {
        margin-left: 20px;
        padding-left: 5px;
        list-style-position: outside;
        margin-bottom: 1em; } }

.FillblankCard {
    input {
        width: 100%;
        padding: 1rem;
        border: 0;
        text-align: center;
        border-radius: 0 0 8px 8px;

        // https://thingsthemselves.com/no-input-zoom-in-safari-on-iphone-the-pixel-perfect-way/
        font-size: 16px; }

    // fieldset svg
 }    //     display: none

.FillblankCard {
    fieldset {
        position: relative; }

    fieldset button {
        position: absolute;
        padding: 0 20px;
        right: 5px;
        height: 100%;
        font-size: 1.3em;
        line-height: 1em;
        border: none;
        background: none; } }

.FillblankCard.correct {
    span.fillblank {
        color: rgba(71, 209, 71, .8); }

    input {
        background: rgba(71, 209, 71, 0.8); }

    input, fieldset svg {
        color: white; }

    .successFeedback {
        margin-top: 1rem;
        color: rgba(0, 151, 0, 0.8);
        text-align: center; } }

.FillblankCard.incorrect {
    span.fillblank {
        color: rgba(255, 77, 77, 0.8); }

    input {
        background: rgba(255, 77, 77, 0.8); }

    input, fieldset svg {
        color: white; }

    .reviseFeedback {
        margin-top: 1rem;
        text-align: center; } }

#effectCanvas {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 1; }

.CardsEmbed {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 7px 50px rgba(46,10,99,.05), 0 1px 1px 0.6px rgba(46,10,99,.1);
    border-radius: 8px;

    header {
        padding-top: 1rem;
        padding-left: 2rem;
        font-size: 0.9rem;
        opacity: 0.5;

        a {
            color: inherit;
            border-bottom: 1px dotted #999; }

        a:hover {
            text-decoration: none; } }

    .complete {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .hanamaru {
            display: block;
            margin: auto;
            margin-bottom: 2rem;
            width: 120px; } } }

.MemoryCard {
    text-align: left;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $mobile) {
        padding-left: 5vw;
        padding-right: 5vw; }

    .prompt {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 2rem;
        font-size: 1.05rem;
        line-height: 2rem;
        white-space: pre-wrap;

        @media (max-width: $mobile) {
            padding: 1.5rem; } }

    span.cloze {
        color: #64b5f6;
        border-bottom: 2px solid #5f6368;
        min-width: 20px;
        display: inline-block;
        text-align: center;
        line-height: 1.5rem; }

    footer {
        height: 3rem;
        display: flex;
        align-items: stretch; }

    button.reveal {
        width: 100%;
        border: 0;
        background: $base-color;
        color: white; }

    button.forgot, button.remembered {
        width: 100%;
        border: 0;
        background: $base-color;
        color: white;

        position: relative;
        text-align: right;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%); } }

    button.forgot {
        margin-right: 0.1rem;

        // Make cross a bit bigger
        svg {
            transform: translateY(-50%) scale(1.3);
            margin-right: 0.1rem; } }

    button.remembered {
        margin-left: 0.1rem;

        // Make circle a bit thicker
        svg path {
            stroke: currentColor;
            stroke-width: 45px; } } }

.SiteHeader .LessonsDropdown {
    position: relative;

    label {
        background: none;
        border: none;
        cursor: pointer;
        margin: 0;
        user-select: none; }

    ul {
        width: 200px;
        display: none;
        position: absolute;
        left: 0;
        padding: 0;
        list-style-type: none;
        background-color: white;
        color: #333;
        box-shadow: 0px 6px 11px rgba(0,0,0,0.15);
        border-radius: 6px; }

    li a {
        display: block;
        padding: 0.5rem 1rem; }

    #lessonsDropdownToggle {
        display: none; }

    #lessonsDropdownToggle:checked ~ ul {
        display: block; } }

.SignupPage, .LoginPage, .ResetPasswordPage, .ResetPasswordFinalizePage {
    height: 100vh;
    display: flex;
    align-items: center;

    form {
        flex-grow: 1;
        padding: 2rem;
        max-width: 360px;
        margin: auto;

        > :first-child {
            margin-top: -20px; } } }

// Fix header overlapping anchor targets by
// always positioning content after the header
@media (min-width: $desktop) {
    .SiteHeader + * {
        position: absolute;
        top: 60px;
        width: 100%; }

    .FrontPage .SiteHeader + * {
        position: relative;
        top: 0; } }

.SiteHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: #fafafa;
    border-bottom: 1px solid #d5d5d5;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);

    .navbar-brand {
        color: #333;
        display: inline-flex;
        align-items: center; }

    .navbar > .container {
        flex-wrap: wrap; }

    li.nav-item > a {
        color: #333; }

    li.signup > a {
        margin-left: 0.5rem;
        @extend .btn-dawn; }

    .ml-auto {
        flex-direction: row;
        align-items: center; }

    .nav-link {
        padding: 0.5rem; }

    @media (max-width: $mobile) {
        position: relative;

        .navbar-nav.other {
            margin-left: auto; } } }

.FrontPage .SiteHeader {
    position: relative;
    background: none;
    border: none;
    box-shadow: none;

    .Logo {
        display: none; }

    a.navbar-brand {
        color: white; }

    .navbar-nav {
        flex-direction: row;
        align-items: center; }

    .LessonsDropdown label {
        color: white; }

    .LessonsDropdown ul li > a {
        color: $landing-color; }

    li.nav-item > a {
        color: white;
        margin: 1rem;
        padding: 0.5rem 0; }

    li.nav-item:last-child > a {
        background: none;
        margin-right: 0;
        padding: 0.5rem 1rem !important;
        color: white;
        border: 2px solid white;
        border-radius: 5px; } }

.FrontPage {
    height: 100%;
    position: relative;

    .intro {
        position: relative;
        // https://www.freevector.com/period-of-time-backgrounds-vector-28385#
        background-image: url("./img/dawnguide-background.jpg");
        background-size: cover;
        background-position: 50% 50%;
        color: white; }

    .jumbotron {
        background: none; }

    .intro:before {
        // Pseudo-background-opacity
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5); }

    .jumbotron > .container {
        max-width: 1024px;
        position: relative;
        height: 100%; }

    .masthead h1 {
        margin-top: 10px;
        font-size: 3.3rem;
        margin-bottom: 2rem; }

    .masthead p {
        font-size: 1.5rem;
        margin-bottom: 2rem; }

    .jumbotron form {
        background: #fafbfc;
        color: #333;
        border-radius: 3px;
        padding: 24px; }

    .jumbotron form .btn-dawn {
        width: 100%;
        padding: 20px 32px; }

    @media (max-width: $mobile) {
        nav ul li a {
            margin: 1rem 0.5rem 1rem; }

        .jumbotron > .container .row {
            display: block; }

        .masthead h1 {
            font-size: 2rem; }

        .masthead p {
            font-size: 1rem; } } }

.FrontPage section.desc {
    margin-top: 2rem;
    text-align: center;

    .container {
        max-width: 800px; } }

.FrontPage section.lessons {
    padding-top: 3rem;

    h3 {
        margin-bottom: 2rem; }

    .lessonsGrid {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-template-rows: repeat(5,auto);
        grid-gap: .5rem; }

    .lesson {
        position: relative;
        padding: 1.5rem;
        transition: box-shadow .3s ease;
        color: #008656; }

    .lesson:hover {
        box-shadow: 0 2px 3px #af8d8d33, 0 4px 10px rgba(0,0,0,.15);
        text-decoration: none; }

    .lesson:after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border: 1px solid;
        opacity: 0;
        transition: opacity .3s ease; }

    .lesson:hover:after {
        opacity: 1; }

    .lesson > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        line-height: 1.2;
        min-height: 150px; }

    .lesson > div:before {
        content: "";
        background-color: currentColor;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: .05;
        z-index: -1; }

    .lesson .summaryLine {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        color: #333; }

    .lesson h5 {
        margin: 0;
        font-size: 1rem;
        text-align: right;
        font-weight: bold; }

    @media (max-width: $mobile) {
        .lessonsGrid {
            display: block; }

        .lesson {
            display: block;
            margin-bottom: 1rem; }

        .lesson:after {
            opacity: 1; } } }

// Fix header overlapping anchor targets by
// always positioning content after the header
@media (min-width: $desktop) {
    .SiteHeader + * {
        position: absolute;
        top: 60px;
        width: 100%; } }

.SiteHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: #fafafa;
    border-bottom: 1px solid #d5d5d5;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);

    .navbar-brand {
        color: #333;
        display: inline-flex;
        align-items: center;
        margin-right: 0.3rem; }

    .navbar > .container {
        flex-wrap: wrap; }

    .environment {
        margin-left: 0.3rem;
        color: green;
        font-size: 0.8rem; }

    @media (max-width: $mobile) {
        position: relative;

        .ml-auto {
            margin-top: 0.3rem;
            margin-left: 0 !important;
            flex-wrap: wrap; }

        .navbar-nav.other {
            margin-left: auto; } }

    ul.navigation-shortcuts {
        display: flex;
        flex: 0 1 auto;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            flex: 0 1 auto;
            width: auto;
            line-height: 20px;
            display: block; }

        li:not(:first-child) {
            margin-left: 8px; }

        li > a {
            border: 1px solid #ddd;
            border-radius: 5px;
            align-items: center;
            background: transparent;
            box-sizing: border-box;
            color: #333;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            font-size: 0.875rem;
            font-weight: 300;
            justify-content: flex-start;
            line-height: 28px;
            margin: 0;
            padding: 0 14px 0 0;
            text-decoration: none;
            transition: border-color 0.2s, background 0.2s; }

        li > a > span {
            border-radius: 4px 0 0 4px;
            background: #fdf4bb;
            border-right: 1px solid #ddd;
            display: block;
            font-size: 0.75rem;
            line-height: 32px;
            margin-right: 12px;
            min-width: 16px;
            padding: 0 8px;
            text-align: center; }

        li > a.inactive {
            pointer-events: none; }

        li > a.inactive > span {
            background: #ccc;
            color: white; } } }

.ReviewPage {
    height: 100vh;

    .cardsContainer {
        height: 100%;
        display: flex;
        align-items: center; } }

.ContentOverview {
    .container {
        max-width: 728px; }

    ul {
        padding: 0; }

    li {
        position: relative;
        list-style-type: none;
        display: flex;
        align-items: center;
        padding: 16px 0 10px; }

    li img {
        width: 168px;
        height: 121px;
        margin-right: 28px;
        object-fit: cover;
        flex-shrink: 0; }

    li:hover {
        background-color: lighten($secondary-color, 5%); }

    li > a {
        padding: 1rem;
        display: flex;
        align-items: center;
        color: inherit; }

    li > a:hover {
        text-decoration: none; }

    li .title {
        display: block;
        font-family: 'SF Compact Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: bold;
        color: $base-color;
        font-size: 18px;
        line-height: 1.1em;
        text-decoration: none; }

    li .subtitle {
        display: block;
        margin-top: 6px;
        color: #757575;
        font-size: 16px;
        line-height: 1.47em;
        text-decoration: none; }

    li .metadata {
        color: #757575;
        padding: 7px 12px 7px 0;
        height: 20px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px; }

    li .marker {
        min-width: 100px;
        height: 100px;
        border: 1px solid rgba(33,36,44,0.50);
        margin-right: 0.75rem;
        position: relative;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .lessonType {
            color: #333;
            width: 0.8rem;
            height: 0.8rem; } }

    li.learned .fillbar {
        position: absolute;
        top: -1px;
        left: -1px;
        width: 1.8rem;
        height: 1.8rem;
        border-bottom: 5px solid #008656;
        border-radius: 10%; }

    @media (max-width: $mobile) {
        li img {
            display: none; } } }

.MasteryProgressBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #666;
    font-size: 0.8rem;
    padding-right: 1rem;
    cursor: pointer;
    margin-top: 1rem;
    float: right;

    .leveltext {
        margin-right: 6px;
        font-size: 0.7rem; }

    .outer, .inner {
        border-radius: 10px; }

    .outer {
        width: 100px;
        height: 5px;
        background: rgba(33,36,44,0.08); }

    .inner {
        height: 100%;
        background-color: transparentize(#9059ff, 0.2);
        transition: background-color .3s ease;

        &.disabled & {
            background-color: rgba(33,36,44,0.3); }

        .outer:not(.mastered) & {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; } }

    @media (max-width: 550px) {
        padding-right: 0.5rem;

        .outer {
            width: 150px; } } }

.MemoriesCollection {
    li {
        list-style-type: none;
        padding: 1rem;
        white-space: pre-wrap;
        border: 1px solid #ccc; }

    span.cloze {
        color: #64b5f6;
        line-height: 1.5rem; }

    p.source {
        color: #666; } }
